import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserService } from './services/user.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private _user: UserService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    let access_token = this._user.accessToken;
    if (access_token) {
      const req = request.clone({
        setHeaders: {
          Authorization: `Bearer ${access_token}`,
        },
      });
      return next.handle(req);
    } else {
      return next.handle(request);
    }
  }
}
