import { Component, OnDestroy, OnInit } from '@angular/core';
import { UserService } from 'app/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

//Metadata
export interface RouteInfo {
  path: string;
  title: string;
  type: string;
  icontype: string;
  children?: ChildrenItems[];
  permission?: string;
}

export interface ChildrenItems {
  path: string;
  title: string;
  ab?: string;
  type?: string;
  permission?: string;
  icontype: string;
}

//Menu Items
export const ROUTES: RouteInfo[] = [
  {
    path: '/dashboard',
    title: 'Inicio',
    type: 'link',
    icontype: 'nc-icon nc-bank',
    permission: 'DASHBOARD',
  },
  {
    path: '/ventas',
    title: 'Ventas',
    type: 'link',
    icontype: 'fal fa-cash-register',
    permission: 'VENTAS',
  },
  {
    path: '/catalogos',
    title: 'Catálogos',
    type: 'sub',
    icontype: 'fal fa-archive',
    children: [
      {
        path: 'categorias',
        title: 'Categorías',
        icontype: 'fal fa-clipboard-list',
        permission: 'CATEGORIAS',
      },
      {
        path: 'colecciones',
        title: 'Colecciones',
        icontype: 'fal fa-layer-group',
        permission: 'COLECCIONES',
      },
      {
        path: 'descuentos',
        title: 'Descuentos',
        icontype: 'fal fa-tag',
        permission: 'DESCUENTOS',
      },
      {
        path: 'productos',
        title: 'Productos',
        icontype: 'fal fa-couch',
        permission: 'PRODUCTOS',
      },
      {
        path: 'propiedades',
        title: 'Propiedades',
        icontype: 'fal fa-couch',
        permission: 'PROPIEDADES',
      },
      {
        path: 'usuarios',
        title: 'Usuarios',
        icontype: 'fal fa-users',
        permission: 'USUARIOS',
      },
    ],
  },
];

@Component({
  selector: 'sidebar-cmp',
  templateUrl: 'sidebar.component.html',
})
export class SidebarComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  constructor(private _user: UserService) {}
  public menuItems: any[];
  isNotMobileMenu() {
    if (window.outerWidth > 991) {
      return false;
    }
    return true;
  }
  public perm = [];
  public user = {};

  ngOnInit() {
    this.menuItems = ROUTES.filter((menuItem) => menuItem);
    this._user.user$.pipe(takeUntil(this.onDestroy$)).subscribe((userToken) => {
      this.user = userToken.user;
      this.perm = userToken.user.permissions;
    });
  }
  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }
}
