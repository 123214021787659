import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseService } from 'app/shared/classes/BaseService';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root',
})
export class ApiService {
  constructor(private http: HttpClient) {}

  public Propiedades = new BaseService(this.http, 'propiedades');
  public Ventas = Object.assign(new BaseService(this.http, 'ventas'), {
    cancelar: (venta: number) =>
      this.http.delete(`${environment.apiUrl}/ventas/cancelar/${venta}`),
    enviar: (
      id: string,
      data: {
        numeroGuia: string;
        paqueteria: string;
        productos: number[] | string[];
      },
    ) => this.http.post(`${environment.apiUrl}/ventas/enviar/${id}`, data),
  });

  public Variantes = new BaseService(this.http, 'variantes');

  public Categorias = Object.assign(new BaseService(this.http, 'categorias'), {
    create: (data: any, file: File) => {
      const fd = new FormData();
      Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof value == 'object') value = JSON.stringify(value);
        fd.append(key, value);
      });
      if (file) fd.append('file', file);
      return this.http.post(`${environment.apiUrl}/categorias`, fd);
    },
    update: (id: number, data: any, file: File) => {
      const fd = new FormData();
      Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof value == 'object') value = JSON.stringify(value);
        fd.append(key, value);
      });
      if (file) fd.append('file', file);
      return this.http.patch(`${environment.apiUrl}/categorias/${id}`, fd);
    },
    getImage: (uuid: string) => {
      return `${environment.apiUrl}/categorias/imagen/${uuid}`;
    },
  });

  public Colecciones = Object.assign(
    new BaseService(this.http, 'colecciones'),
    {
      create: (data: any, file: File) => {
        const fd = new FormData();
        Object.keys(data).forEach((key) => {
          let value = data[key];
          if (typeof value == 'object') value = JSON.stringify(value);
          fd.append(key, value);
        });
        if (file) fd.append('file', file);
        return this.http.post(`${environment.apiUrl}/colecciones`, fd);
      },
      update: (id: number, data: any, file: File) => {
        const fd = new FormData();
        Object.keys(data).forEach((key) => {
          let value = data[key];
          if (typeof value == 'object') value = JSON.stringify(value);
          fd.append(key, value);
        });
        if (file) fd.append('file', file);
        return this.http.patch(`${environment.apiUrl}/colecciones/${id}`, fd);
      },
      getImage: (uuid: string) => {
        return `${environment.apiUrl}/colecciones/imagen/${uuid}`;
      },
    },
  );

  public Descuentos = Object.assign(new BaseService(this.http, 'descuentos'), {
    search: (term: string) => {
      return this.http.get<any[]>(`${environment.apiUrl}/descuentos/search`, {
        params: { term },
      });
    },
  });

  public Productos = Object.assign(new BaseService(this.http, 'productos'), {
    search: (term: string) => {
      return this.http.get<any[]>(`${environment.apiUrl}/productos/search`, {
        params: { term },
      });
    },
    getImage: (uuid: string) => {
      return `${environment.apiUrl}/productos/imagen/${uuid}`;
    },
    create: (data: any, files: File[]) => {
      const fd = new FormData();
      Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof value == 'object' && value) value = JSON.stringify(value);
        fd.append(key, value || '');
      });
      if (files && files.length)
        files.forEach((file, i) => {
          fd.append('file', file);
        });
      return this.http.post(`${environment.apiUrl}/productos`, fd);
    },
    update: (id: number, data: any, files: File[]) => {
      const fd = new FormData();
      Object.keys(data).forEach((key) => {
        let value = data[key];
        if (typeof value == 'object' && value)
          value = JSON.stringify(value || '');
        fd.append(key, value || '');
      });
      if (files && files.length)
        files.forEach((file, i) => {
          fd.append('file', file);
        });
      return this.http.patch(`${environment.apiUrl}/productos/${id}`, fd);
    },
  });
  public Usuarios = Object.assign(new BaseService(this.http, 'users'), {
    changePassword: (data: {
      id: string | number;
      newPassword: string;
      oldPassword: string;
    }) => {
      return this.http.post(`${environment.apiUrl}/users/changePassword`, data);
    },
  });

  public Envio = {
    paises: () => {
      return this.http.get<{ name: string; id: string }[]>(
        `${environment.apiUrl}/countries`,
      );
    },
    estados: () => {
      return this.http.get<{ name: string; id: string }[]>(
        `${environment.apiUrl}/states`,
      );
    },
  };
}
