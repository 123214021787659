import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AngularTokenService } from 'angular-token';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxPermissionsService } from 'ngx-permissions';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { UserService } from 'app/services/user.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

declare var $: any;

@Component({
  selector: 'login-cmp',
  templateUrl: './login.component.html',
})
export class LoginComponent implements OnInit, OnDestroy {
  private onDestroy$ = new Subject<void>();
  form: FormGroup;
  test: Date = new Date();
  private toggleButton;
  private sidebarVisible: boolean;
  private nativeElement: Node;
  public disableButtons = false;

  constructor(
    private element: ElementRef,
    private _user: UserService,
    private router: Router,
    private toastr: ToastrManager,
    private permissionsService: NgxPermissionsService,
    private formBuilder: FormBuilder,
  ) {
    this.nativeElement = element.nativeElement;
    this.sidebarVisible = false;
  }

  ngOnInit() {
    this.checkFullPageBackgroundImage();
    var body = document.getElementsByTagName('body')[0];
    body.classList.add('login-page');
    var navbar: HTMLElement = this.element.nativeElement;
    this.toggleButton = navbar.getElementsByClassName('navbar-toggle')[0];

    setTimeout(function () {
      // after 1000 ms we add the class animated to the login/register card
      $('.card').removeClass('card-hidden');
    }, 700);

    this.form = this.formBuilder.group({
      username: ['', Validators.required],
      password: ['', Validators.required],
    });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    var body = document.getElementsByTagName('body')[0];
    body.classList.remove('login-page');
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  checkFullPageBackgroundImage() {
    var $page = $('.full-page');
    var image_src = $page.data('image');

    if (image_src !== undefined) {
      var image_container =
        '<div class="full-page-background" style="background-image: url(' +
        image_src +
        ') "/>';
      $page.append(image_container);
    }
  }

  login() {
    if (this.form.invalid) return;
    this._user
      .logIn(this.form.value)
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(
        (userToken) => {
          this.permissionsService.loadPermissions(userToken.user.permissions);
          this.router.navigate(['/dashboard']);
        },
        (err) =>
          this.toastr.errorToastr(err, 'Error', {
            maxShown: 1,
            animate: 'slideFromBottom',
          }),
      );
  }

  sidebarToggle() {
    var toggleButton = this.toggleButton;
    var body = document.getElementsByTagName('body')[0];
    var sidebar = document.getElementsByClassName('navbar-collapse')[0];
    if (this.sidebarVisible == false) {
      setTimeout(function () {
        toggleButton.classList.add('toggled');
      }, 500);
      body.classList.add('nav-open');
      this.sidebarVisible = true;
    } else {
      this.toggleButton.classList.remove('toggled');
      this.sidebarVisible = false;
      body.classList.remove('nav-open');
    }
  }

  get f(): any {
    return this.form.controls;
  }
}
