import { Component, OnInit } from '@angular/core';
import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';
import { UserService } from './services/user.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent implements OnInit {
  constructor(
    private permissionsService: NgxPermissionsService,
    private _user: UserService,
  ) {}

  ngOnInit() {
    const userToken = this._user.userToken;
    if (userToken) {
      const { permissions } = userToken.user;
      this.permissionsService.loadPermissions(permissions);
    }
  }
}
