import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

export class BaseService {
  constructor(public http: HttpClient, private route: string) {}

  find(options?: any) {
    return this.http.get<any[]>(`${environment.apiUrl}/${this.route}`, {
      params: { options: JSON.stringify(options || {}) },
    });
  }

  count(options?: any) {
    return this.http.get<any>(`${environment.apiUrl}/${this.route}/count`, {
      params: { options: JSON.stringify(options || {}) },
    });
  }

  create(data: any) {
    return this.http.post<any>(`${environment.apiUrl}/${this.route}`, data);
  }

  update(id: number, data: any) {
    return this.http.patch<any>(
      `${environment.apiUrl}/${this.route}/${id}`,
      data,
    );
  }

  remove(id: string) {
    return this.http.patch<any>(`${environment.apiUrl}/${this.route}/${id}`, {
      status: 'inactivo',
    });
  }
}
