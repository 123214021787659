import {
  AfterViewInit,
  Component,
  Inject,
  InjectionToken,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder } from '@angular/forms';
import { TableColumn, DatatableComponent } from '@swimlane/ngx-datatable';
import { ApiService } from 'app/services/api.service';
import { ToastrManager } from 'ng6-toastr-notifications';
import { NgxSmartModalService } from 'ngx-smart-modal';
import { BehaviorSubject, forkJoin, of, Subject } from 'rxjs';
import { switchMap, takeUntil, finalize } from 'rxjs/operators';

declare type actions = 'add' | 'edit' | 'delete';

export const DATA_SERVICE = new InjectionToken<ApiService>('data.service');

@Component({
  selector: 'app-base-component',
  template: '',
})
export class BaseComponent implements OnInit, AfterViewInit, OnDestroy {
  public dataModel: string;
  constructor(
    @Inject(DATA_SERVICE) public _data: ApiService,
    public _fb: FormBuilder,
    public _modal: NgxSmartModalService,
    public _toast: ToastrManager,
  ) {}

  @ViewChild('dataTable') dataTable: DatatableComponent;

  public onDestroy$ = new Subject<void>();

  //Req
  public data$ = () =>
    of({}).pipe(
      switchMap(() => {
        this.loading = true;
        this._data[this.dataModel].count();
        const data$ = this._data[this.dataModel].find(this.filters);
        const count$ = this._data[this.dataModel].count({
          where: this.filters.where,
        });
        return forkJoin([data$, count$]);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => (this.loading = false)),
    );
  public add$ = (data: any) =>
    of({}).pipe(
      switchMap(() => {
        if (this.form.invalid) throw 'Formulario inválido.';
        this.requesting = true;
        return this._data[this.dataModel].create(data);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      }),
    );
  public update$ = (data: any) =>
    of({}).pipe(
      switchMap(() => {
        if (this.form.invalid) throw 'Formulario inválido.';
        this.requesting = true;
        return this._data[this.dataModel].update(data.id, data);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      }),
    );
  public delete$ = (id: string | number) =>
    of({}).pipe(
      switchMap(() => {
        this.requesting = true;
        return this._data[this.dataModel].remove(id);
      }),
      takeUntil(this.onDestroy$),
      finalize(() => {
        this.requesting = false;
      }),
    );

  //Form
  public action$ = new BehaviorSubject<actions>(null);
  public form = this._fb.group({});
  public filtersForm = this._fb.group({});

  //Data
  public model = '';
  public models = '';
  public pronombre: 'a' | 'o' = 'o';
  public data: any[] = [];
  public filteredData: any[] = [];
  public loading = false;
  public requesting = false;
  public mapData = (data: any[]) => {
    return data;
  };

  //Table
  public selectedRows: any[] = [];
  public selectedRow: any;
  public columns: TableColumn[] = [];
  //Pagination
  public pagination = {
    page: 0,
    limit: 10,
    count: 0,
    skip: function () {
      return this.page * this.limit;
    },
    limitOptions: [10, 25, 50, 100],
  };

  //Getters
  get action() {
    return this.action$.value;
  }

  get disabled() {
    if (this.action === 'add' || this.action === 'edit')
      return this.form.invalid || this.requesting;
    else this.requesting;
  }

  get filters() {
    const filters = {
      where: { status: 'activo' },
      take: this.pagination.limit,
      skip: this.pagination.skip(),
    };
    return filters;
  }

  //Cycles
  ngOnInit() {
    this.getData();
  }

  ngAfterViewInit(): void {
    this.initListeners();
  }

  ngOnDestroy(): void {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  //Listeners
  initListeners() {
    //On Action Change
    this.onActionChange();
  }

  onActionChange() {
    this.action$.pipe(takeUntil(this.onDestroy$)).subscribe(
      (action) => {
        if ((action === 'delete' || action === 'edit') && !this.selectedRow) {
          this._toast.errorToastr(
            `Seleccione un${this.pronombre === 'a' ? this.pronombre : ''} ${
              this.model
            } para ${action === 'delete' ? 'eliminar' : 'editar'}`,
            'Error',
          );
          return;
        }
        this.open(action);
      },
      (err) => this._toast.errorToastr(err, 'Error'),
    );
  }

  onModalClose() {
    this._modal
      .get('manageModal')
      .onClose.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.close();
      });
    this._modal
      .get('manageModal')
      .onClose.pipe(takeUntil(this.onDestroy$))
      .subscribe(() => {
        this.close();
      });
  }

  //Req Data
  getData() {
    this.data$().subscribe(
      ([data, count]: any) => {
        this.data = this.mapData(data);
        this.filteredData = this.data;
        this.pagination.count = count;
      },
      (err) => {
        this._toast.errorToastr(
          `Error al obtener ${this.models}, verifica tu conexión de internet`,
        );
      },
    );
  }

  //Modals & Form
  open(action: actions) {
    if (action === 'add') {
      this._modal.get('manageModal').open();
    }
    if (action === 'edit') {
      this.form.patchValue(this.selectedRow);
      this._modal.get('manageModal').open();
    }
    if (action === 'delete') {
      this.form.patchValue(this.selectedRow);
      this._modal.get('deleteModal').open();
    }
  }

  close() {
    if (this.action$.value === 'delete') this._modal.get('deleteModal').close();
    else {
      this._modal.get('manageModal').close();
    }
    this.form.reset();
  }

  reset() {
    this.form.reset();
    this.selectedRows = [];
    this.selectedRow = undefined;
  }

  onSubmit() {
    if (this.action === 'add') this.add();
    if (this.action === 'delete') this.delete();
    if (this.action === 'edit') this.edit();
  }

  //Request Actions
  add() {
    const data = this.cleanObj(this.form.value);
    this.add$(data).subscribe(
      (res) => {
        this._toast.successToastr(
          `${this.model} agregad${this.pronombre} correctamente.`,
        );
        this.close();
        this.reset();
        this.getData();
      },
      (err) => {
        this._toast.errorToastr(err, 'Error');
      },
    );
  }

  edit() {
    const data = {
      ...this.cleanObj(this.form.value),
      id: this.selectedRow.id,
    };
    this.update$(data).subscribe(
      (res) => {
        this._toast.successToastr(
          `${this.model} editad${this.pronombre} correctamente.`,
        );
        this.close();
        this.reset();
        this.getData();
      },
      (err) => {
        this._toast.errorToastr(err, 'Error');
      },
    );
  }

  delete() {
    this.delete$(this.selectedRow.id).subscribe(
      (res) => {
        this._toast.successToastr(
          `${this.model} eliminad${this.pronombre} correctamente.`,
        );
        this.close();
        this.reset();
        this.getData();
      },
      (err) => {
        this._toast.errorToastr(err, 'Error');
      },
    );
  }
  //Pagination
  setPage(pageInfo) {
    this.pagination.page = pageInfo.page - 1;
    this.getData();
  }
  onLimitChange(value) {
    this.pagination.page = 0;
    this.pagination.limit = parseInt(value);
    this.getData();
  }

  //Utils
  onSelectedRow(event: any) {
    this.selectedRows = event.selected;
    if (this.selectedRows && this.selectedRows.length)
      this.selectedRow = this.selectedRows[0];
  }

  invalidControl(control: AbstractControl) {
    if (!control) return;
    return control.invalid && control.touched;
  }

  getPronombre(plural?: boolean) {
    if (this.pronombre === 'a') {
      if (plural) return 'las';
      else return 'la';
    }
    if (this.pronombre === 'o') {
      if (plural) return 'los';
      else return 'el';
    }
    return '';
  }

  cleanObj(obj: any) {
    Object.keys(obj).forEach((key) => {
      if (obj[key] === null) {
        delete obj[key];
      }
    });
    return obj;
  }

  filterDatatable(event) {
    if (event.target.value === '') {
      this.filteredData = this.data;
    }
    const buscado = event.target.value.toLowerCase();
    // get the key names of each column in the dataset
    const columns = this.columns.filter((c) => c);
    // get the amount of columns in the table
    const colsAmt = columns.length;
    const keys: any = columns.map((column) => {
      return column.prop;
    });
    // assign filtered matches to the active datatable
    this.filteredData = this.data.filter(function (row) {
      // iterate through each row's column data
      for (let i = 0; i < colsAmt; i++) {
        if (keys[i].indexOf('.') != -1) {
          // es un objeto el atributo
          if (row[keys[i].split('.')[0]]) {
            if (
              row[keys[i].split('.')[0]][keys[i].split('.')[1]]
                .toString()
                .toLowerCase()
                .indexOf(buscado) !== -1 ||
              !buscado
            ) {
              // lo encontró
              return true;
            }
          }
        } else {
          // es un atributo
          if (row[keys[i]]) {
            if (
              row[keys[i]].toString().toLowerCase().indexOf(buscado) !== -1 ||
              !buscado
            ) {
              // lo encontró
              return true;
            }
          }
        }
      }
    });
    this.dataTable.offset = 0; // regresar a la pagina 1
  }
}
